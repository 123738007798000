import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Product } from "../../models/product";
import { withCancellation } from "../../utility/preact.utility";
import { Text } from "preact-i18n";

import ImageBrowserComponent from "../../components/image-browser.component";
import ItemDescriptionComponent from "../../components/item-description.component";
import AddToCartButton from "../../components/add-to-cart.component";

import itemService from "../../services/items.service";
import cartService from "../../services/cart.service";
import { ImageForPreview } from "../../models/image-for-preview";

const ProductDetailsPage: FunctionalComponent<{ id: string }> = (props) => {
    const [product, setProduct] = useState<Product | undefined>(undefined);
    const [images, setImages] = useState<ImageForPreview[]>([]);

    useEffect(() => withCancellation(async signal => {
        const product = await itemService.getItem(props.id);
        if (!signal.aborted) {
            setProduct(product);
            setImages(product?.images.map(i => ({ ...i, caption: `products.${product.id}.name` })) || []);
        }
    }), []);

    const addCurrentToCart = () => cartService.addToCart(product!);

    return (
        product
            ? <div id="product-details" class="container">
                <h1> <Text id={`products.${product.id}.name`}></Text> </h1>
                <ImageBrowserComponent items={images}></ImageBrowserComponent>
                <AddToCartButton item={product} onAddToCart={addCurrentToCart} ></AddToCartButton>
                <ItemDescriptionComponent item={product}></ItemDescriptionComponent>
            </div>
            : <p> Loading product data...</p>
    );
};

export default ProductDetailsPage;
