import { CredentialStorageService } from "./credential-storage.service";


export abstract class HttpServiceBase {

    constructor(protected credentialStorage: CredentialStorageService) {

    }

    get(url: string, abort?: AbortSignal) {
        return this.request("GET", url, abort);
    }

    put(url: string, body: Object, abort?: AbortSignal) {
        return this.request("PUT", url, body, abort);
    }

    post(url: string, body: Object, abort?: AbortSignal) {
        return this.request("POST", url, body, abort);
    }

    patch(url: string, body: Object, abort?: AbortSignal) {
        return this.request("PATCH", url, body, abort);
    }

    delete(url: string, abort?: AbortSignal) {
        return this.request("DELETE", url, abort);
    }

    private async request(method: "GET" | "PUT" | "POST" | "PATCH" | "DELETE", url: string, body: any, abort?: AbortSignal) {
        const headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        if (this.credentialStorage.getToken())
            headers['Authorization'] = `Bearer ${this.credentialStorage.getToken()}`;

        const result = await fetch(url, {
            method,
            signal: abort,
            body: JSON.stringify(body),
            headers
        });

        if (result.status == 201 || result.status == 204)
            return null;
        else if (result.ok)
            return await result.json();
        else
            throw new Error(JSON.stringify(await result.json()))
    }

}