import { Text, withText } from "preact-i18n";
import { getFormValue } from "../utility/form.utility";


/** A shorthand object for easier access to component translations */
const translations = {
    currentPassword: <Text id="components.changePassword.placeholder.currentPassword"> Current Password </Text>,
    newPassword: <Text id="components.changePassword.placeholder.newPassword"> New Password </Text>,
    repeatNewPassword: <Text id="components.changePassword.placeholder.repeatNewPassword"> Repeat New Password </Text>,
    submit: <Text id="components.changePassword.btn.submit"> Submit </Text>,
    reset: <Text id="components.changePassword.btn.reset"> Reset </Text>,
}

type Translations = Record<keyof typeof translations, string>;

type ChangePasswordProps = {
    onSubmit?: (currentPassword: string, newPassword: string, repeatNewPassword: string) => void
}


/** Displays the contents the user's cart */
const ChangePasswordComponent = withText<ChangePasswordProps>(translations)((props: ChangePasswordProps & Translations) => {
    const onSubmit = (event: Event) => {
        event.preventDefault();
        if (props.onSubmit) {
            const { currentPassword, newPassword, repeatNewPassword } = getFormValue<any>(event.target as HTMLFormElement);
            props.onSubmit(currentPassword, newPassword, repeatNewPassword);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <input type="password" name="currentPassword" placeholder={props.currentPassword}></input>
            <input type="password" name="newPassword" placeholder={props.newPassword}></input>
            <input type="password" name="repeatNewPassword" placeholder={props.repeatNewPassword}></input>

            <div class="buttons">
                <button> {props.submit} </button>
                <button type="reset"> {props.reset} </button>
            </div>
        </form>
    );
});

export default ChangePasswordComponent;