import { Product } from "../models/product";
import { ProductType } from "../models/product-type";



export class ItemService {

    private allItems?: Product[];

    async getAllCached() {
        if (!this.allItems) {
            this.allItems = await fetch('/api/products')
                .then(resp => resp.json())
                .then(products => products.map((p: unknown) => Product.fromJson(p)));
        }

        return this.allItems!;
    }

    async getItems(type?: ProductType): Promise<ReadonlyArray<Product>> {
        const items = await this.getAllCached();
        return type != null ? items.filter(item => item.type === type) : items;
    }

    async getItem(id: string) {
        const items = await this.getAllCached();
        return items.find(item => item.id == id);
    }

}

/** Default implementation of item services. Can be used as a singleton */
const itemService = new ItemService();
export default itemService;