import { Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import AddToCartButton from "../../components/add-to-cart.component";
import ImageBrowserComponent from "../../components/image-browser.component";
import { Product } from "../../models/product";
import { ProductType } from "../../models/product-type";
import { Text, withText } from "preact-i18n";
import cartService from "../../services/cart.service";
import itemService from "../../services/items.service";

import "./drones.page.scss";
import { ImageForPreview } from "../../models/image-for-preview";
import ProductListViewComponent from "../../components/product-list-view";

const translations = {
    title: <Text id="pages.drones.title"> Drones </Text>
}

type Translations = Record<keyof typeof translations, string>;

const DronesPage = withText(translations)((props: Translations) => {
    const [items, setItems] = useState<readonly Product[] | null>(null);
    const [currentItem, setCurrent] = useState<Product | null>(null);
    const [images, setImages] = useState<ImageForPreview[] | null>(null);

    useEffect(() => {
        itemService.getItems(ProductType.Drone)
            .then(items => {
                setItems(items);
                setCurrent(items[0]);
                setImages(items.map(i => ({ ...i.images[0], caption: `products.${i.id}.name`, link: `/products/${i.id}` })))
            });
        document.title = props.title
    }, []);

    const addCurrentToCart = () => currentItem ? cartService.addToCart(currentItem) : {};

    return (
        <div id="drones">
            <h1>{props.title}</h1>

            { items && images && currentItem
                ? <Fragment>
                    <ImageBrowserComponent items={images} onItemSelected={index => setCurrent(items[index] as Product)} ></ImageBrowserComponent>
                    <AddToCartButton item={currentItem} onAddToCart={addCurrentToCart} ></AddToCartButton>

                    <section id="list-view">
                        {items && <ProductListViewComponent products={items}></ProductListViewComponent>}
                    </section>
                </Fragment>
                : <p> Loading data... </p>
            }
        </div>
    );
});

export default DronesPage;
