import { FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import { Link } from "preact-router/match";
import { useContext } from "preact/hooks";
import cartService from "../services/cart.service";
import userService from "../services/user.service";

const HeaderComponent: FunctionalComponent<{ switchLanguage?: () => void }> = props => {
    const userState = useContext(userService.userContext);
    const cartState = useContext(cartService.cartContext);

    const accClick = () => userState?.setLoginRequested(true);
    const switchLanguage = (event: MouseEvent) => {
        if (props.switchLanguage) props?.switchLanguage();
        (event.target as HTMLImageElement).classList.toggle("active")
    }

    const cartTotal = cartState?.items.reduce((total, item) => total + item.quantity, 0) || 0;

    return (
        <header class="header">
            <nav>
                <Link activeClassName="active" href="/">  <Text id="header.references.home"> Home</Text>  </Link>
                <Link activeClassName="active" href="/electronics"> <Text id="header.references.electronics"> Electronics </Text> </Link>
                <Link activeClassName="active" href="/drones"> <Text id="header.references.drones"> Drones </Text> </Link>


                {userState?.currentUser
                    ? <Link class="link-with-icon" activeClassName="active" href="/profile">
                        <img src="/assets/images/user-icon.svg"></img>
                        <span>{userState.currentUser.email}</span>
                    </Link>
                    : <a class="link-with-icon" onClick={accClick}>
                        <img src="/assets/images/user-icon.svg"></img>
                        <span> <Text id="header.references.account"> Account </Text></span>
                    </a>
                }

                <Link class="link-with-icon" activeClassName="active" href="/cart">
                    <img src="/assets/images/cart-icon.svg"></img>
                    <span><Text id="header.references.cart"> Cart </Text></span>
                    <span class="badge"> {cartTotal}  </span>
                </Link>

                <button class="language-select" onClick={switchLanguage}>
                    <img src="/assets/images/flag.png"></img>
                </button>
            </nav>
        </header>
    );
};

export default HeaderComponent;
