import { FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import { useState } from "preact/hooks";
import { Product } from "../models/product";
import { repeatMap } from "../utility/iteration.utility";

const MAX_STARS = 5;

/** Displays details for a given item  */
const ItemDescriptionComponent: FunctionalComponent<{ item: Product }> = props => {
    const [currentTab, setCurrent] = useState<number>(0);
    const showFeatures = () => setCurrent(0);
    const showSpecs = () => setCurrent(1);

    return (
        <div class="item-description">
            <ul class="tab-names">
                <li className={currentTab == 0 ? "active" : undefined} onClick={showFeatures} > <Text id="components.item-description.features.title"> FEATURES </Text>  </li>
                <li className={currentTab == 1 ? "active" : undefined} onClick={showSpecs}> <Text id="components.item-description.specs.title"> SPECIFICATIONS </Text>  </li>
            </ul>

            { currentTab == 0
                ? (
                    <section class="item-features">
                        <ul>
                            {props.item.features.map(feature => (
                                <li class="rated-feature">
                                    <span class="rating">
                                        {feature.rating ? repeatMap(() => (<span class="star fill"> ☆ </span>), feature.rating) : null}
                                        {feature.rating ? repeatMap(() => (<span class="star"> ☆ </span>), MAX_STARS - feature.rating) : null}
                                    </span>
                                    <span class="feature-name">  <Text id={"features." + feature.description}>  </Text> </span>
                                </li>
                            ))
                            }
                        </ul>
                    </section>
                )
                : (
                    <section class="item-specifications">
                        <dl>
                            {props.item.specifications.map(spec =>
                                <div class="specification">
                                    <dt> <Text id={"specifications." + spec.name}>  </Text>: </dt>
                                    <dd>{spec.value} </dd>
                                </div>
                            )}
                        </dl>
                    </section>
                )
            }
        </div>
    );
}

export default ItemDescriptionComponent;