import "./style/index.scss";
import "./style/dialogs.scss";
import "./style/forms.scss";
import "./style/shapes.scss";
import "./style/components/previews.scss";
import "./style/components/auth.scss";
import "./style/components/header.scss";
import "./style/components/footer.scss";

import Layout from "./layout.tsx";

if (typeof window !== "undefined" && process.env.NODE_ENV == "production") {
    window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date;
    ga('create', 'UA-204985881-1', {
        'storage': 'none'
    });
    ga('send', 'pageview');
}

export default Layout;
