import { FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import { PurchasedItem } from "../services/cart.service";

interface CartViewProps {
    items: readonly PurchasedItem[];
    remove?: (index: number) => void;
    add?: (index: number) => void;

}


/** Displays the contents the user's cart */
const CartViewComponent: FunctionalComponent<CartViewProps> = props => {
    const removeItem = (index: number) => props.remove?.(index);
    const addItem = (index: number) => props.add?.(index);

    return (
        <section class="cart">
            {props.items.map((item, index) => (
                <div class="cart-item">
                    <img src={item.product.images[0].thumbnailUrl}></img>
                    <span> <Text id={`products.${item.product.id}.name`}></Text>  </span>
                    <span> {item.product.price} <Text id="general.currency.short">лв</Text>  </span>
                    <button class="btn-primary cart-btn" onClick={() => removeItem(index)}> - </button>
                    <span> {item.quantity} </span>
                    <button class="btn-primary cart-btn" onClick={() => addItem(index)}> + </button>
                </div>
            ))
            }
        </section>
    );
};

export default CartViewComponent;