import { Localizer, Text, withText } from "preact-i18n";
import { getFormValue } from "../utility/form.utility";

export type LoginData = { email: string, password: string };

/** Data that needs to be passed to login component by parent */
interface LoginProps {
    onSubmit?: (data: LoginData) => void;
    onClose?: () => void;
    showTitle?: boolean
}

/** A shorthand object for easier access to component translations */
const translations = {
    email: <Text id="components.login.placeholder.email"> Email </Text>,
    password: <Text id="components.login.placeholder.password"> Password </Text>,
    submitBtn: <Text id="components.login.btn.submit"> Submit </Text>,
    cancelBtn: <Text id="components.login.btn.cancel"> Cancel </Text>,
}

type Translations = Record<keyof typeof translations, string>;


const LoginComponent = withText<LoginProps>(translations)((props: LoginProps & Translations) => {
    function onSubmit(event: Event) {
        const formData = getFormValue<LoginData>(event.target as HTMLFormElement);
        event.preventDefault();
        props.onSubmit?.(formData);
    }

    const onClose = () => props.onClose?.();

    return (
        <form action="#" onSubmit={onSubmit} >
            { props.showTitle ? <header> <Text id="components.login.title">LOGIN</Text> </header> : null}
            <Localizer>
                <input type="email" name="email" placeholder={props.email}></input>
                <input type="password" name="password" placeholder={props.password}></input>
            </Localizer>

            <div class="buttons">
                <button> {props.submitBtn} </button>
                <button type="reset" onClick={onClose}> {props.cancelBtn} </button>
            </div>
        </form >
    )
})

export default LoginComponent;
