import { FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import { ProductPreview } from "../models/product-preview";

interface AddComponentProps {
    item: ProductPreview;
    onAddToCart?: () => void;
}


/** Component containing a price tag and add to cart button */
const AddToCartButton: FunctionalComponent<AddComponentProps> = props => {
    const addToCart = () => props.onAddToCart?.();

    return (
        <div class="add-to-cart">
            <label class="price-tag"> {props.item.price} <Text id="general.currency.short">лв</Text>  </label>
            <button class="btn-primary" onClick={addToCart} > <Text id="components.cart.btn.add"> Add To Cart</Text>  </button>
        </div>
    );
}

export default AddToCartButton;