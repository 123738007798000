export class MetaService {

    setTitle(title: string) {
        document.title = title;
    }

    setDescription(description: string) {
        const el = document.querySelector("meta[name='description']");
        if (el) el.setAttribute('content', description)
    }

    setLanguage(language: string) {
        document.documentElement.lang = language;
    }

}

const metaService = new MetaService();
export default metaService;