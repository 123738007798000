import { FunctionComponent } from "preact";
import { Text } from "preact-i18n";
import { Link } from "preact-router/match";

const FooterComponent: FunctionComponent = () => (
    <footer>
        <div class="footer-info">
            <section class="about">
                <header> <Text id="footer.about.title">Some extra info </Text> </header>
                <p>  <Text id="footer.about.text1">Some extra info </Text> </p>
                <p>  <Text id="footer.about.text2">Some extra info </Text> </p>
            </section>
            <section class="terms">
                <ul>
                    <li> <Link onClick={() => window.scroll(0, 0)} href="/info/faq" > <Text id="footer.references.faq"> FAQ </Text> </Link> </li>
                    <li> <Link onClick={() => window.scroll(0, 0)} href="/info/terms" > <Text id="footer.references.terms"> Terms </Text> </Link> </li>
                    <li> <Link onClick={() => window.scroll(0, 0)} href="/info/policy" > <Text id="footer.references.privacy"> Privacy policy </Text> </Link>  </li>
                    <li> <Link onClick={() => window.scroll(0, 0)} href="/info/legal" > <Text id="footer.references.legal"> Legal </Text> </Link>  </li>
                </ul>
            </section>
        </div>
        <div class="contacts">
            <ul>
            <li> <Text id="footer.contacts.phone"> +359123123 </Text>  </li>
                <li class="separator"> &middot; </li>
                <li> <Text id="footer.contacts.email"> support@stuff.tech </Text>  </li>
                <li class="separator"> &middot; </li>
                <li> <Text id="footer.contacts.address"> Non-existing str, Nowhere, Nowhere </Text> </li>
            </ul>
        </div>
        <div class="copyright">
            <Text id="footer.copyright.text"> © 2021 Copyright Text</Text>
        </div>
    </footer>
);

export default FooterComponent;