import { hasArray } from "../utility/object.utility";
import { ProductPreview } from "./product-preview";

export class Product extends ProductPreview {

    specifications: ReadonlyArray<{
        id: string,
        name: string,
        value: string
    }>;

    features: ReadonlyArray<{
        name: string,
        description: string,
        rating: 0 | 1 | 2 | 3 | 4 | 5 | null,
    }>;

    constructor(other: Omit<Product, "detailsUrl">) {
        super(other.id, other.type, other.price, other.shippingPrice, other.images)
        this.specifications = other.specifications || [];
        this.features = other.features || [];
    }

    get detailsUrl() {
        return `/products/${this.id}`;
    }

    static fromJson(json: unknown) {
        const baseObj = ProductPreview.fromJson(json);
        if (!hasArray(json, "features", Object)) throw new Error("Invalid features provided");
        if (!hasArray(json, "specifications", Object)) throw new Error("Invalid specifications provided");

        return new Product({ ...json, ...baseObj });
    }
}