import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import ImageBrowserComponent from "../../components/image-browser.component";
import { ImageForPreview } from "../../models/image-for-preview";
import { Product } from "../../models/product";
import itemService from "../../services/items.service";
import { Text } from "preact-i18n";

import "./home.page.scss";

const HomePage: FunctionalComponent = () => {
    const [items, setItems] = useState<readonly Product[] | null>(null);
    const [images, setImages] = useState<ImageForPreview[] | null>(null);

    useEffect(() => {
        itemService.getItems().then(items => {
            setItems(items);
            setImages(items.map(i => ({ ...i.images[0], caption: `products.${i.id}.name`,  link: `/products/${i.id}` })))
        });
        document.title = "Home"
    }, []);

    return (
        <div id="home">
            <section id="home-title">
                <h1> <Text id="pages.home.title"> Home </Text> </h1>
            </section>
            <section id="item-display">
                {items && images
                    ? <ImageBrowserComponent items={images}></ImageBrowserComponent>
                    : <p> <Text id="general.loading.text"> Loading... </Text> </p>
                }
            </section>
        </div>
    );
};

export default HomePage;
