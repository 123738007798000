import { FunctionalComponent } from "preact";
import { Text, translate, useText } from "preact-i18n";
import { useState } from "preact/hooks";
import { ImageForPreview } from "../models/image-for-preview";
import ImagePreviewComponent from "./image-preview.component";

interface ImageBrowserProperties {
    items: ReadonlyArray<ImageForPreview>;
    onItemSelected?(index: number): void;
}


/** Carousel-like component for browsing items */
const ImageBrowserComponent: FunctionalComponent<ImageBrowserProperties> = props => {
    const [currentIndex, setCurrent] = useState<number>(0);

    /** Changes the image index by an offset - handles negative numbers and keeps index within bounds */
    function changeImage(index: number) {
        const safeIndex = (index < 0 ? props.items.length - 1 : index) % props.items.length;
        setCurrent(safeIndex);
        props.onItemSelected?.(safeIndex);
    }

    const nextImage = () => changeImage(currentIndex + 1);
    const previousImage = () => changeImage(currentIndex - 1);
    const imageCaption = (index: number) => useText(props.items[index].caption).caption;

    return (
        <div class="item-browser">
            <section class="current-item">
                <button class="arrow left" onClick={previousImage} > </button>
                <ImagePreviewComponent item={props.items[currentIndex]} ></ImagePreviewComponent>
                <button class="arrow right" onClick={nextImage} > </button>
            </section>
            <section class="thumbnails">
                {props.items.map((item, index) =>
                    <div className={"thumbnail-container " + (index == currentIndex ? 'active' : '')}>
                        <img onClick={() => changeImage(index)} src={item.thumbnailUrl} alt={imageCaption(currentIndex)} ></img>
                    </div>
                )}
            </section>
        </div>
    )
};

export default ImageBrowserComponent;
