import { has, hasArray, hasOptional } from "../utility/object.utility";
import { ProductImage } from "./product-image";
import { ProductType } from "./product-type";

export class ProductPreview {
    constructor(
        public readonly id: string,
        public readonly type: ProductType,
        public readonly price: number,
        public readonly shippingPrice: number,
        public readonly images: ReadonlyArray<ProductImage>,
        public readonly manufacturer?: string,
        public readonly category?: string,
    ) { }

    static fromJson(other: unknown) {
        if (typeof other !== "object" || other == null) throw new Error("Invalid product provided - must be an object");
        if (!has(other, "id", "string")) throw new Error("Invalid product id");
        if (!has(other, "type", "number")) throw new Error("Invalid product type provided");
        if (!has(other, "price", "number")) throw new Error("Invalid price provided");
        if (!has(other, "shippingPrice", "number")) throw new Error("Invalid shippingPrice provided");
        if (!hasOptional(other, "manufacturer", "string")) throw new Error("Invalid manufacturer provided");
        if (!hasOptional(other, "category", "string")) throw new Error("Invalid category provided");
        if (!hasArray(other, "images", Object)) throw new Error("Invalid Images array provided");

        return new ProductPreview(other.id, other.type, other.price, other.shippingPrice, other.images.map(ProductImage.fromJson), other.manufacturer, other.category);
    }
}