import { FunctionalComponent } from "preact";
import { Product } from "../models/product";
import { Text } from "preact-i18n";
import { sortArray } from "../utility/array.utility";

interface ProductListViewProperties {
    products: ReadonlyArray<Product>;
}


/** Carousel-like component for browsing items */
const ProductListViewComponent: FunctionalComponent<ProductListViewProperties> = props => {
    return (
        <ul class="product-list">
            {
                  sortArray(props.products, (a, b) => a.price - b.price).map(p =>
                    <li>
                        <a href={p.detailsUrl}>
                            <div class="image-holder">
                                <img src={p.images[0].imageUrl}></img>
                            </div>
                            <header> <Text id={`products.${p.id}.name`}></Text>  </header>
                            <span> {p.price} <Text id="general.currency.short">лв</Text>  </span>
                        </a>
                    </li>
                )
            }
        </ul>
    )
};

export default ProductListViewComponent;
