import { Text, withText } from "preact-i18n";
import { RegistrationData } from "../models/registration-data";
import { getFormValue } from "../utility/form.utility";

/** A shorthand object for easier access to component translations */
const translations = {
    title: <Text id="components.register.title"> Register </Text>,
    name: <Text id="components.register.placeholder.name">Name</Text>,
    email: <Text id="components.register.placeholder.email">Email</Text>,
    password: <Text id="components.register.placeholder.password">Password</Text>,
    repeatPassword: <Text id="components.register.placeholder.repeat-password">Repeat Password</Text>,
    address: <Text id="components.register.placeholder.address"> Address</Text>,
    submitBtn: <Text id="components.register.btn.submit">Submit</Text>,
    cancelBtn: <Text id="components.register.btn.cancel">Cancel</Text>,
}

type Translations = Record<keyof typeof translations, string>;

/** The register properties that can be passed by the parrent */
interface RegisterProps {
    onSubmit?: (data: RegistrationData) => void;
    onClose?: () => void;
    showTitle?: boolean;
}

const RegisterComponent = withText<RegisterProps>(translations)((props: RegisterProps & Translations) => {
    const onSubmit = (event: Event) => {
        event.preventDefault();

        const formData = getFormValue<RegistrationData>(event.target as HTMLFormElement);

        if (formData.password !== formData.repeatPassword) alert("Passwords do not match!");
        else props.onSubmit?.(formData);
    }

    const onClose = () => props.onClose?.();

    return (
        <form action="#" onSubmit={onSubmit} >
            { props.showTitle ? <header> {props.title} </header> : null}
            <input type="email" name="email" placeholder={props.email} required></input>
            <input type="password" name="password" placeholder={props.password} required></input>
            <input type="password" name="repeatPassword" placeholder={props.repeatPassword} required></input>

            <div class="buttons">
                <button> {props.submitBtn} </button>
                <button type="reset" onClick={onClose}> {props.cancelBtn} </button>
            </div>
        </form>
    )
})
export default RegisterComponent;