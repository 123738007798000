import { Fragment, FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import { useContext, useEffect, useState } from "preact/hooks";
import CartViewComponent from "../../components/cart-view.component";
import PersonalDataComponent from "../../components/personal-data";
import { PersonalData } from "../../models/user";
import cartService from "../../services/cart.service";
import userService from "../../services/user.service";

import "./cart.page.scss"

const CartPage: FunctionalComponent = () => {
    const userState = useContext(userService.userContext);
    const { items } = useContext(cartService.cartContext)!;
    const [readyToCheckout, setReadyToCheckout] = useState<boolean>(userState?.currentUser ? true : false);

    useEffect(() => { userState?.currentUser != null ? setReadyToCheckout(true) : {} }, [userState?.currentUser]);

    const removeItem = (index: number) => cartService.removeFromCart(items[index].product.id);
    const addItem = (index: number) => cartService.addToCart(items[index].product);
    const productTotal = items.reduce((sum, item) => sum + (item.product.price * item.quantity), 0);
    const shippingTotal = items.reduce((sum, item) => sum + (item.product.shippingPrice * item.quantity), 0);

    const submitForm = (userData: PersonalData) => {
        if (!userService.userState.currentUser?.comparePersonalData(userData) && confirm('Save contacts data to user?'))
            userService.updatePersonalData(userData)
                .catch(err => console.error('Failed to save personal data', err));

        cartService.submit(userData)
            .then(() => alert('order complete'))
            .then(() => cartService.clear())
            .catch(() => alert('failed to save cart'));

    };

    return (
        <div id="cart-page">
            {
                items.length > 0
                    ? (
                        <div id="cart-preview">
                            <CartViewComponent items={items} remove={removeItem} add={addItem} ></CartViewComponent>
                            <dl class="cart-summary">
                                <dt> <Text id="cart.text.product-total">Products:</Text>  </dt>
                                <dd> {productTotal} <Text id="general.currency.short">лв</Text>  </dd>

                                <dt> <Text id="cart.text.shipping-cost">Shipping:</Text> </dt>
                                <dd> {shippingTotal} <Text id="general.currency.short">лв</Text> </dd>

                                <strong>
                                    <dt> <Text id="cart.text.total">Total:</Text> </dt>
                                    <dd>  {productTotal + shippingTotal} <Text id="general.currency.short">лв</Text>  </dd>
                                </strong>
                            </dl>

                            {!readyToCheckout &&
                                <section class="actions">
                                    <button class="btn-primary" onClick={() => setReadyToCheckout(true)} > <Text id="cart.btn.proceed"> Continue As Guest </Text> </button>
                                    <button class="btn-primary" onClick={() => userState?.setLoginRequested(true)} > <Text id="cart.btn.proceed"> Login </Text> </button>
                                </section>}

                            { readyToCheckout &&
                                <Fragment>
                                    <h2> <Text id="cart.header.recipient"> Recipient data </Text> </h2>
                                    <PersonalDataComponent onSubmit={submitForm}></PersonalDataComponent>
                                </Fragment>
                            }
                        </div>

                    )
                    : (
                        <p> <Text id="cart.text.empty">You have no items in the cart</Text>  </p>
                    )
            }
        </div>
    );
}

export default CartPage;