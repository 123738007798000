import { Context, createContext } from "preact";
import { useState } from "preact/hooks";
import { ProductPreview } from "../models/product-preview";
import { User } from "../models/user";
import { HttpServiceBase } from "./http.service.base";
import defaultCredentialStorage from "./credential-storage.service";

export type PurchasedItem = { product: ProductPreview, quantity: number };

export interface CartState {
    items: PurchasedItem[];
    setItems: (items: PurchasedItem[]) => void;
}


export class CartService extends HttpServiceBase {

    private cartItems = new Map<string, PurchasedItem>();

    readonly cartContext: Context<CartState | null>;

    cartState!: CartState;


    constructor(credentialStorage = defaultCredentialStorage) {
        super(credentialStorage);
        this.cartContext = createContext<CartState | null>(null);
    }

    initializeState() {
        const [items, setItems] = useState<PurchasedItem[]>([]);
        return this.cartState = { items, setItems };
    }

    getCart(): ReadonlyArray<PurchasedItem> {
        return Array.from(this.cartItems.values());
    }

    addToCart(item: ProductPreview) {
        if (!this.cartItems.has(item.id))
            this.cartItems.set(item.id, { product: item, quantity: 0 });

        this.cartItems.get(item.id)!.quantity++;
        this.updateCart();
    }

    removeFromCart(itemId: string) {
        const item = this.cartItems.get(itemId);

        if (item && item.quantity > 1)
            item.quantity--;
        else
            this.cartItems.delete(itemId);

        this.updateCart();
    }

    clear() {
        this.cartItems.clear();
        this.updateCart();
    }

    async submit(userData: Partial<User>, abort?: AbortSignal) {
        try {
            await this.post("/api/orders", { ...userData, products: this.preparePurchasedQuantities() }, abort);
        } catch (error) {
            throw new Error("Failed to save cart. " + error.message)
        }
    }

    private updateCart(){
        this.cartState.setItems(Array.from(this.cartItems.values()));
        sessionStorage.setItem('cart', JSON.stringify(Array.from(this.cartItems.values())));
    }

    private preparePurchasedQuantities() {
        const result: { [id: string]: number } = {};
        for (const [id, purchase] of this.cartItems.entries()) {
            result[id] = purchase.quantity;
        }
        return result;
    }
}

/** Default implementation of cart service. Can be used as a singleton */
const cartService = new CartService();
export default cartService;