import { has, hasOptional } from "../utility/object.utility";

export class ProductImage {
    constructor(
        public readonly imageUrl: string,

        public readonly thumbnailUrl: string = imageUrl
    ) { }


    static fromJson(other: unknown) {
        if (typeof other !== "object" || other == null) throw new Error("Invalid product provided - must be an object");
        if (!has(other, "imageUrl", "string")) throw new Error("Invalid image url provided");
        if (!hasOptional(other, "thumbnailUrl", "string")) throw new Error("Invalid thumbnailUrl provided");

        return new ProductImage(other.imageUrl, other.thumbnailUrl || other.imageUrl);
    }
}