import { Component } from "preact";
import { Text } from "preact-i18n";
import { useContext } from "preact/hooks";
import userService, { UserState } from "../services/user.service";
import LoginComponent, { LoginData } from "./login.component";
import RegisterComponent from "./register.component";

interface AuthModalProps {
    onLogin?: (data: LoginData) => void;
    onRegister?: (data: any) => Promise<boolean>;
    onClose?: () => void;
}

/** Combines login and registration forms as a modal dialog */
export default class AuthenticationModalComponent extends Component<AuthModalProps, { currentSection: "login" | "register" }> {

    componentDidMount() {
        this.setState({ currentSection: "login" });
    }

    onLogin(data: LoginData) {
        this.props.onLogin?.(data);
    }

    onRegister(data: any) {
        if (this.props.onRegister)
            this.props.onRegister?.(data).then(isSuccess => isSuccess ? this.setState({ currentSection: "login" }) : {});
    }

    onClose(state: UserState) {
        state.setLoginRequested(false);
        this.props.onClose?.();
    }

    setSection(section: "login" | "register") {
        this.setState({ currentSection: section });
    }

    render() {
        const userState = useContext(userService.userContext)!;

        return (
            <section id="auth" className={(userState.loginRequested) ? "modal visible" : "modal"}>
                <div class="modal-content">
                    <div id="auth-container">
                        <ul class="tab-names">
                            <li className={this.state.currentSection == "login" ? "active" : undefined} onClick={() => this.setSection("login")} >
                                <Text id="components.auth.btn.login"> LOGIN </Text>
                            </li>
                            <li className={this.state.currentSection == "register" ? "active" : undefined} onClick={() => this.setSection("register")}>
                                <Text id="components.auth.btn.register"> REGISTER </Text>
                            </li>
                        </ul>

                        {this.state.currentSection == "login"
                            ? <LoginComponent onSubmit={this.onLogin.bind(this)} onClose={() => this.onClose(userState)}></LoginComponent>
                            : <RegisterComponent onSubmit={this.onRegister.bind(this)} onClose={() => this.onClose(userState)}></RegisterComponent>
                        }
                    </div>
                </div>
            </section>
        )
    }
}
