import { has, hasOptional } from "../utility/object.utility";

const optionalProps = ["firstName", "lastName", "address", "phone"] as const;

type OptionalProps = typeof optionalProps[number]

export type PersonalData = Pick<User, OptionalProps | "email">;

export class User {
    constructor(
        public id: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public address: string,
        public phone: string
    ) {

    }

    /** Updates the user personal data and returns whether any actual changes were made */
    updatePersonalData(data: Pick<User, OptionalProps>) {
        for (const p of optionalProps) {
            if (data[p] && this[p] !== data[p]) {
                this[p] = data[p] as string;
            }
        }
    }

    /** returns true if the personal data is the same */
    comparePersonalData(data: Pick<User, OptionalProps>){
        return optionalProps.every(p => this[p] == data[p]);
    }

    static empty() {
        return new User('', '', '', '', '', '');
    }

    static fromJson(user: unknown) {
        if (typeof user !== "object" || user == null) throw new Error("Invalid user provided - must be a json object");
        if (!has(user, "id", "string")) throw new Error("Invalid user id");
        if (!has(user, "email", "string")) throw new Error("Invalid email provided for user");
        if (!hasOptional(user, "firstName", "string")) throw new Error("Invalid firstName provided");
        if (!hasOptional(user, "lastName", "string")) throw new Error("Invalid lastName provided");
        if (!hasOptional(user, "address", "string")) throw new Error("Invalid address provided");
        if (!hasOptional(user, "phone", "string")) throw new Error("Invalid phone provided");

        return new User(user.id, user.email, user.firstName, user.lastName, user.address, user.phone);
    }
}