import { Fragment, FunctionalComponent } from "preact";
import { route } from "preact-router";
import { getFormValue } from "../../utility/form.utility";
import { User } from "../../models/user";
import PersonalDataComponent from "../../components/personal-data";
import credentialStorageService from "../../services/credential-storage.service";
import userService from "../../services/user.service";
import { Text } from "preact-i18n";
import ChangePasswordComponent from "../../components/change-password.component";


const ProfilePageComponent: FunctionalComponent = () => {
    if (!credentialStorageService.isLoggedIn) {
        route('/', true);
        return <Fragment></Fragment>;
    }

    const changePersonalData = (data: User) => {
        userService.updatePersonalData(data)
            .then(() => alert("Saved succesfully"))
            .catch(() => alert("Failed to save user data"))
    };

    const changePassword = (event: Event) => {
        event.preventDefault();

        const form = event.target as HTMLFormElement;
        const { currentPassword, repeatNewPassword, newPassword } = getFormValue(form);

        if (newPassword != repeatNewPassword) return alert('Passwords do not match!');

        userService.changePassword(currentPassword, newPassword)
            .then(() => alert("Saved succesfully"))
            .catch(() => alert("Failed to save user data"))
    }

    return (
        <div class="container" id="user-profile-page">
            <button onClick={() => userService.logout()} style="float:right" class="btn-primary"> <Text id="pages.profile.btn.logout"> Logout </Text> </button>

            <section id="personal-data">
                <h1> <Text id="pages.profile.personal.title"> Personal Data </Text> </h1>
                <PersonalDataComponent onSubmit={changePersonalData} canEditEmail={false} ></PersonalDataComponent>
            </section>

            <section id="personal-data">
                <h1> <Text id="pages.profile.password.title"> Change Password </Text> </h1>
                <ChangePasswordComponent onSubmit={changePassword} ></ChangePasswordComponent>
            </section>
        </div>
    );
}

export default ProfilePageComponent;