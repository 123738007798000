import { User } from "../models/user";

const TOKEN_KEY = "auth-token";
const USER_KEY = "logged-user";

export class CredentialStorageService {

    get isLoggedIn() { return !!this.getToken() };

    getToken() {
        return sessionStorage.getItem(TOKEN_KEY);
    }

    setToken(value: string) {
        sessionStorage.setItem(TOKEN_KEY, value)
    }

    getCurrentUser() {
        if (typeof window !== "undefined"){
            const serializedUser = sessionStorage.getItem(USER_KEY);
            return serializedUser ? User.fromJson(JSON.parse(serializedUser)) : null;
        }
        return null;
    }

    setCurrentUser(user: User | null) {
        if (user == null) sessionStorage.removeItem(USER_KEY);
        else sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    clear() {
        sessionStorage.clear();
    }
}

export default new CredentialStorageService();