import { FunctionalComponent } from "preact";
import { Route, Router } from "preact-router";
import HomePage from "./pages/home/home.page";
import NotFoundPage from "./pages/notfound/not-found.page";
import HeaderComponent from "./components/header.component";
import AuthenticationModalComponent from "./components/authorization-modal.component";
import definition from './assets/localization/en.json';
import { IntlProvider } from 'preact-i18n';
import DronesPage from "./pages/drones/drones.page";
import ElectronicsPage from "./pages/electronics/electronics.page";
import CartPage from "./pages/cart/cart.page";
import userService from "./services/user.service";
import FooterComponent from "./components/footer.component";
import { LoginData } from "./components/login.component";
import { RegistrationData } from "./models/registration-data";
import ProductDetailsPage from "./pages/product-details/product-details.page";
import cartService from "./services/cart.service";
import InfoPage from "./pages/info/info.page";
import { useState } from "preact/hooks";
import ProfilePageComponent from "./pages/profile/profile.page";
import metaService from "./services/meta.service";

const UserContext = userService.userContext;
const CartContext = cartService.cartContext;

const Layout: FunctionalComponent = () => {
    const userState = userService.initializeState();
    const cartState = cartService.initializeState();

    const [translations, setTranslations] = useState<Object>(definition);
    const [language, setLanguage] = useState<"en" | "bg">("en");

    const changeLanguage = async () => {
        const newLang = language == "en" ? "bg" : "en";
        const data = await fetch(`/assets/localization/${newLang}.json`).then(r => r.json());
        setLanguage(newLang);
        setTranslations(data);
        metaService.setLanguage(newLang);
    }

    const login = async (data: LoginData) => {
        try {
            const result = await userService.login(data);
            userState.setUser(result);
            userState.setLoginRequested(false);
        } catch (error) {
            alert(error.message);
        }
    };

    const register = async (data: RegistrationData) => {
        try {
            await userService.register(data);
            //defaultState.setLoginRequested(false);
            alert("Registration Succesful!");
            return true;
        } catch (error) {
            alert("Failed to register user. " + error.message);
            return false;
        }
    };

    const routeChange = () => {
        if (process.env.NODE_ENV == "production" && typeof window !== "undefined") {
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageview');
        }
    }

    return (
        <div id="app">
            <div class="banner"> </div>
            <IntlProvider definition={translations}>
                <UserContext.Provider value={userState}>
                    <CartContext.Provider value={cartState}>
                        <HeaderComponent switchLanguage={() => changeLanguage()} />
                        <AuthenticationModalComponent onLogin={login} onRegister={register} />
                        <main id="current-page">
                            <Router onChange={routeChange}>
                                <Route path="/" component={HomePage} />
                                <Route path="/drones" component={DronesPage} />
                                <Route path="/electronics" component={ElectronicsPage} />
                                <Route path="/cart" component={CartPage} />
                                <Route path="/products/:id" component={ProductDetailsPage} />
                                <Route path="/info/:pageName" component={InfoPage} />
                                <Route path="/profile" component={ProfilePageComponent} />
                                <NotFoundPage default />
                            </Router>
                        </main>
                        <FooterComponent></FooterComponent>
                    </CartContext.Provider>
                </UserContext.Provider>
            </IntlProvider>

        </div>
    );
};

export default Layout;
