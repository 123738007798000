import { BuiltInTypes, Constructor } from "./type.utility";

/** Returns whether an object contains a key of given typе. Must be used in a conditional statement
 * @example
 *   const obj: unknown = {a:1, b:2, c:3};
 *   if(has(obj), "b", "number") return obj.b;
*/
export function has<K extends PropertyKey, T extends keyof BuiltInTypes>(obj: any, key: K, type: T): obj is { [P in K]: BuiltInTypes[T] } {
    return (key in obj) && type === typeof obj[key];
}

/** Returns whether an object contains a key of given typе. Must be used in a conditional statement
 * @example
 *   const obj: unknown = {a:1, b:2, c:3};
 *   if(has(obj), "b", "number") return obj.b;
*/
export function hasOptional<K extends PropertyKey, T extends keyof BuiltInTypes>(obj: any, key: K, type: T): obj is { [P in K]: BuiltInTypes[T] } {
    return typeof obj[key] === type || obj[key] == null; 
}

/** Returns whether an object contains an  array of given typе. Must be used in a conditional statement
 * @example
 *   const obj: unknown = {a:1, b:2, c:3};
 *   if(has(obj), "b", "number") return obj.b;
*/
export function hasArray<K extends PropertyKey, T extends Constructor<any>>(obj: any, key: K, type: T): obj is { [P in K]: Array<any> } {
    return (key in obj) && Array.isArray(obj[key]) && obj[key].every((item: any) => item instanceof type);
}

