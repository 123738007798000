import { Text, withText } from "preact-i18n";
import { PersonalData, User } from "../models/user";
import credentialStorageService from "../services/credential-storage.service";
import { getFormValue } from "../utility/form.utility";


/** A shorthand object for easier access to component translations */
const translations = {
    address: <Text id="components.personalData.placeholder.address"> address </Text>,
    firstName: <Text id="components.personalData.placeholder.firstName"> firstName </Text>,
    email: <Text id="components.personalData.placeholder.email"> email </Text>,
    lastName: <Text id="components.personalData.placeholder.lastName"> lastName </Text>,
    phone: <Text id="components.personalData.placeholder.phone"> Phone </Text>,
    submit: <Text id="components.personalData.btn.submit"> Submit </Text>
}

type Translations = Record<keyof typeof translations, string>;

type SubmitPurchaseProps = {
    onSubmit?: (userData: PersonalData) => void
    canEditEmail?: boolean
}


/** Displays the contents the user's cart */
const PersonalDataComponent = withText<SubmitPurchaseProps>(translations)((props: SubmitPurchaseProps & Translations) => {
    const user = credentialStorageService.getCurrentUser() || User.empty();
    const onSubmit = (event: Event) => {
        event.preventDefault();
        if (props.onSubmit) {
            const data = getFormValue<PersonalData>(event.target as HTMLFormElement);
            props.onSubmit(data);
        }
    }

    return (
        <form action="#" onSubmit={onSubmit} >
            <input type="email" name="email" value={user.email} placeholder={props.email} readOnly={props.canEditEmail == false} ></input>
            <input type="text" name="firstName" value={user.firstName} placeholder={props.firstName}></input>
            <input type="text" name="lastName" value={user.lastName} placeholder={props.lastName}></input>
            <textarea type="text" name="address" value={user.address} placeholder={props.address}></textarea>
            <input type="text" name="phone" value={user.phone} placeholder={props.phone}></input>

            <div class="buttons">
                <button> {props.submit} </button>
            </div>
        </form>
    );
});

export default PersonalDataComponent;