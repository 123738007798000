import { Context } from "preact";
import { createContext } from "preact";
import { useState } from "preact/hooks";
import { LoginData } from "../components/login.component";
import { RegistrationData } from "../models/registration-data";
import { PersonalData, User } from "../models/user";
import defaultCredentialStorage from "./credential-storage.service";
import { HttpServiceBase } from "./http.service.base";

export interface UserState {
    currentUser: User | null;

    loginRequested: boolean;

    setUser: (user: User | null) => void;

    setLoginRequested: (value: boolean) => void;
}


export class UserService extends HttpServiceBase {

    readonly userContext: Context<UserState | null>;

    userState!: UserState;


    constructor(credentialStorage = defaultCredentialStorage) {
        super(credentialStorage);
        this.userContext = createContext<UserState | null>(null);
    }

    initializeState(): UserState {
        const [currentUser, setUserState] = useState<User | null>(this.credentialStorage.getCurrentUser());
        const [loginRequested, setLoginRequested] = useState<boolean>(false);

        const setUser = (user: User | null) => {
            this.credentialStorage.setCurrentUser(user);
            setUserState(user);
        }

        return this.userState = { currentUser, setUser, loginRequested, setLoginRequested };
    }

    async login(data: LoginData, abort?: AbortSignal) {
        try {
            const body = await this.post("/api/sessions", data, abort)
            this.credentialStorage.setToken(body.token);
            return User.fromJson(body.user);
        } catch (error) {
            console.error(error);
            throw new Error("Login failed");
        }
    }

    async register(data: RegistrationData, abort?: AbortSignal) {
        try {
            await this.post("/api/users", data, abort);
        } catch (error) {
            console.error(error);
            throw new Error("Failed to register. " + error.message)
        }
    }

    async updatePersonalData(data: PersonalData, abort?: AbortSignal) {
        try {
            const user = this.credentialStorage.getCurrentUser();
            if (user){
                user.updatePersonalData(data)
                await this.put("/api/users/personal-data", user, abort);
                this.userState.setUser(user);
            }

            return user;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to set user data. " + error.message)
        }
    }

    async changePassword(currentPassword: string, newPassword: string, abort?: AbortSignal) {
        try {
            await this.put("/api/users/password", { currentPassword, newPassword }, abort);
        } catch (error) {
            throw new Error("Failed to change password. " + error.message)
        }
    }

    logout() {
        this.userState.setUser(null);
        this.credentialStorage.clear();
    }
}

/** Default implementation for user service. Can be used as singleton */
const userService = new UserService();
export default userService;