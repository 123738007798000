import { Fragment } from "preact";
import ImageBrowserComponent from "../../components/image-browser.component";
import ProductListViewComponent from "../../components/product-list-view";
import itemService from "../../services/items.service";
import cartService from "../../services/cart.service";
import AddToCartButton from "../../components/add-to-cart.component";
import { useEffect, useState } from "preact/hooks";
import { Product } from "../../models/product";
import { Text, withText } from "preact-i18n";
import { ProductType } from "../../models/product-type";
import { withCancellation } from "../../utility/preact.utility";
import { ImageForPreview } from "../../models/image-for-preview";

import "./electronics.page.scss";

const translations = {
    title: <Text id="pages.electronics.title"> Electroncis </Text>
}

type Translations = Record<keyof typeof translations, string>;


const ElectronicsPage = withText(translations)((props: Translations) => {
    const [items, setItems] = useState<readonly Product[] | null>(null);
    const [currentItem, setCurrent] = useState<Product | null>(null);
    const [images, setImages] = useState<ImageForPreview[] | null>(null);


    useEffect(() => withCancellation(async signal => {
        document.title = props.title;
        const data = await itemService.getItems(ProductType.Electronics);
        if (!signal.aborted) {
            setItems(data);
            setCurrent(data[0]);
            setImages(data.map(i => ({ ...i.images[0],  caption: `products.${i.id}.name`, link: `/products/${i.id}` })))
        }
    }), []);

    const addCurrentToCart = () => cartService.addToCart(currentItem!);
    return (
        <div id="electronics">
            <h1> {props.title} </h1>

            { items && images && currentItem
                ? <Fragment>
                    <ImageBrowserComponent items={images} onItemSelected={index => setCurrent(items[index] as Product)} ></ImageBrowserComponent>
                    <AddToCartButton item={currentItem} onAddToCart={addCurrentToCart} ></AddToCartButton>
                    <section id="list-view">
                        {items && <ProductListViewComponent products={items}></ProductListViewComponent>}
                    </section>
                </Fragment>
                : <p> Loading data... </p>
            }
        </div>
    );
});

export default ElectronicsPage;
