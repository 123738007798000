import { FunctionalComponent } from "preact";
import { MarkupText, Text } from "preact-i18n";

const InfoPage: FunctionalComponent<{ pageName: string }> = props =>
    <div class="container">
        <h1>  <Text id={`pages.${props.pageName}.title`}> ... </Text> </h1>
        <p> <MarkupText id={`pages.${props.pageName}.text`}> ... </MarkupText> </p>
    </div>

export default InfoPage;
