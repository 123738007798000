import { FunctionalComponent } from "preact";
import { ImageForPreview } from "../models/image-for-preview";
import { Text, useText } from "preact-i18n";

/** Displays the image of a single item on the screen */
const ImagePreviewComponent: FunctionalComponent<{ item: ImageForPreview }> = props => {

    const imageCaption = () =>
        <figcaption>
            {props.item.showCaption ? <Text id={props.item.caption}> </Text> : <br />}
        </figcaption>

    const figureData = () =>
        <figure>
            <div class="image-container">
                <img src={props.item.imageUrl} alt={useText(props.item.caption).name}></img>
            </div>
            {imageCaption()}
        </figure>

    return props.item.link
        ? <a class="image-link" href={props.item.link}> {figureData()} </a>
        : figureData()
}

export default ImagePreviewComponent;
