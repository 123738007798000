/** Represents a type of input */
export type FormInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;


/** Gets the form input as a given object type - does not perform any validation */
export function getFormValue<T extends Object>(form: HTMLFormElement) {
    const inputs = Object.values(form.elements).filter(el => isInput(el)) as FormInput[];
    const formData = inputs.reduce((result, el) => ({ ...result, [el.name]: el.value }), {});
    return formData as T;
};

export function setFormValue(form: HTMLFormElement, data: any) {
    const inputs = Object.values(form.elements).filter(el => isInput(el)) as FormInput[];
    inputs.forEach(el => el.value = data[el.name].toString());
};

/** Determines whether an HTML Element is some type of input */
export function isInput<T extends Element>(element: T) {
    const knownInputs = [HTMLInputElement, HTMLTextAreaElement, HTMLSelectElement];
    return knownInputs.some(inputType => element instanceof inputType);
}